




























import Vue from 'vue';
import Component from 'vue-class-component';
import CommonProfileImage from 'common-modules/src/components/CommonProfileImage.vue';
import { Prop } from 'vue-property-decorator';
import { Task } from 'common-modules/src/store/interface/Task';
import { Submission } from '@/store/interface/Grade';

const LmsCourseDocument = () => import('./LmsCourseDocument.vue');

@Component({
  components: {
    LmsCourseDocument,
    CommonProfileImage,
  },
})
export default class JwlGradeView extends Vue {
  @Prop(Object)
  submission!: Submission;

  @Prop(Object)
  task!: Task;

  localizedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString();
  }

  get gradeSubmissionClass (): string {
    const submitted = this.submission;
    const baseClass = 'jwl-grade-view';

    if (submitted) {
      if (submitted.grade) {
        if (submitted.grade.date) {
          const isPointTaskAnd0Grade = !this.isAttendanceTask
            && this.task.maxScore > 0
            && Number(submitted.grade.score) === 0;
          const isAttendanceAndNotAttended = this.isAttendanceTask
            && !submitted.grade.attended;

          if (isPointTaskAnd0Grade || isAttendanceAndNotAttended) {
            return `${baseClass}--0-grade`;
          }
          return `${baseClass}--grade`;
        }
        return `${baseClass}--unpublished`;
      }
      return `${baseClass}--ungraded`;
    }
    return `${baseClass}--nothing`;
  }

  get isAttendanceTask (): boolean {
    return this.task.type === 'attendance';
  }

  get attended (): boolean {
    return !!this.submission?.grade?.date
      && !!this.submission?.grade?.attended;
  }
}
